import {useContext} from 'react'

import {ApplicationContext, ApplicationProvider} from './Application'


const useApplication: any[] = () => {
  return useContext(ApplicationContext)
}

export {useApplication, ApplicationProvider}
