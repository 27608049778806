import React from "react"
import jwt_decode from "jwt-decode"


const idToken = sessionStorage.getItem('ident')

const initialState = {
  accounts: null,
  activeAccount: null,
  subject: (!!idToken) ? jwt_decode(idToken) : null,
  identityToken: null,
  error: null,
  fatalError: null,
  contentLoading: false
}


const reducer = (oldState, newState) => {
  return {...oldState, ...newState}
}


export const ApplicationContext = React.createContext({
  state: initialState,
  dispatch: () => null
})


export const ApplicationProvider = ({children}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  return (
    <ApplicationContext.Provider value={[ state, dispatch ]}>
      {children}
    </ApplicationContext.Provider>
  )
}
