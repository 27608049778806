import { createContext, useContext, useState } from 'react'
import {
  IonContent,
  IonHeader,
  IonToolbar
} from '@ionic/react'

import SideMenu from '../components/SideMenu'
import './Base.css'


interface IApplicationPageContext {
  error: ReactNode | string | null
  setFatalError?: (arg0: React.ReactNode|string) => void
}


const ApplicationPageContext = createContext<IApplicationPageContext>({
  error: null
})

const useCurrentPage = () => useContext(ApplicationPageContext)


type ApplicationPageProps = {
  children?: React.ReactNode
  gutter: number | null | undefined
  maxWidth: number | null | undefined
  menu?: Object | null | undefined
  title: string | React.ReactNode
}


const ApplicationPage: React.FC<ApplicationPageProps> = ({
  children,
  gutter,
  menu,
  title,
  maxWidth
}) => {
  const sideMenu = (!!menu)
    ? (<SideMenu displayMode="detail" {...menu}/>)
    : null
  const toolbar = (typeof title === "string")
    ? (
      <IonToolbar>
        <h2>{title}</h2>
      </IonToolbar>
    )
    : null
  const classes = (!sideMenu)
    ? "content content-fullwidth"
    : "content"
  const contentStyle = {
    height: "100%",
    padding: (gutter !== undefined) ? gutter : "24px",
    overflowX: "scroll"
  }
  if (!!maxWidth) contentStyle.maxWidth = `${maxWidth}px`

  // Set up the context for this current page.
  const [error, setFatalError] = useState(null)
  const ctx = {
    error,
    setFatalError
  }

  // If the error is not null, then a fatal error occurred an no application
  // content must be rendered.

  return (
    <IonContent id="current-page" scrollY={false}>
      <div className="app">
        {sideMenu}
        <section className={classes} style={{minHeight: "0px", overflowX: "hidden"}}>
          <ApplicationPageContext.Provider value={ctx}>
            <IonHeader mode="ios">
              {toolbar}
            </IonHeader>
            <div style={contentStyle}>
              {(!error) ? children : error}
            </div>
          </ApplicationPageContext.Provider>
        </section>
      </div>
    </IonContent>
  )
}


export default ApplicationPage
export { useCurrentPage }
