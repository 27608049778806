import axios from 'axios';
import jwt_decode from 'jwt-decode'
import ClientOAuth2 from 'webid-client'

import Resource from './Resource'


interface IServiceAgent {
  clearCredentials: () => void
  configureOAuth2: (string, string, any) => void
  hasAccessToken: () => boolean
  hasRefreshToken: () => boolean
  identityProvider: string
  oauth2: ClientOAuth2
  refreshToken: () => void
  persistToken: (any) => void
  processResponse: (object) => object
  resources: any
  setup: (any) => void
  subject: any
  tokenService: string
}


class ServiceAgent<IServiceAgent> {

    constructor({
      identityProvider,
      objectClasses,
      resources,
      services,
      tokenService
    }) {
      this.axios = axios.create()
      this.identityProvider = identityProvider
      this.tokenService = tokenService
      this.token = null
      this.oauth2 = null
      this.objectClasses = (objectClasses !== undefined)
        ? objectClasses
        : {}
      this.subject = null
      this.resources = (!!resources) ? resources : []
      for (const i in services) {
        var spec = services[i]
        this.resources[spec.name] = new Resource(spec)
      }

      // Intercept error response from the Security Token Service (STS).
      // These occur when the access token from the Identity
      // Provider (IdP) is expired.
      this.axios.interceptors.response.use(
        (response) => { return response },
        async (error) => await this.handleErrorResponse(error)
      )
    }

    /* Clears the credentials from the browser storage.*/
    clearCredentials() {
      sessionStorage.removeItem('openid.ident')
      sessionStorage.removeItem('openid.access')
      sessionStorage.removeItem('openid.refresh')
    }

    /* Initiates the OAuth 2.0 login flow.*/
    initiateLogin(onAuthenticated) {
      const self = this
      const state = "s" + (Math.random() + 1).toString(36).substring(7)
      const uri = self.oauth2.code.getUri({state: state})
      const popup = window.open(uri, state);
      window.addEventListener(
        "message", (event) => {
          self.onRedirect(event, popup, state, onAuthenticated)
        }
      )
    }

    /* Handle a redirect from the SSO provider.*/
    onRedirect(event, popup, state, onAuthenticated) {
      // Chrome plugins may send events
      if (event.data.state !== state) {
        console.log("CRITICAL: State mismatch.")
        return
      }
      popup.close()
      const self = this
      this.oauth2.code.getToken(event.data.url)
        .then((token) => {
          self.token = token
          self.subject = jwt_decode(token.data.id_token)
          self.persistToken(token)
          onAuthenticated(self.subject)
        })
    }

    /* Perform additional processing of responses. The default
       implementation determines if we have a handler class for
       the object type specified in the response (if any), and
       returns the result. If there is no handler class, then the
       response is returned as-is.
    */
    processResponse(service: Resource, dto: object) {
      const self = this
      var result = dto

      // If these members are present then we assume that the
      // Data Transfer Object (DTO) conforms to the Unimatrix
      // APIs format.
      if (!!dto.apiVersion && !!dto.kind) {
        // If there is a .items member, then the object is a
        // list.
        if (dto.items !== undefined) {
          dto.items = dto.items.map((dto: object, index: number) => {
            return self.processResponse(service, dto)
          })
        } else {
          const qualName = `${dto.apiVersion}/${dto.kind}`
          const ObjectClass  = this.objectClasses[qualName]
          console.log(`DEBUG: Deserializing ${qualName}`)
          if (ObjectClass !== undefined) {
            result = new ObjectClass(service, dto)
          }
        }
      }
      return result
    }

    async configureOAuth2(authorizationUri, tokenUri, notify) {
      const self = this
      this.oauth2 = new ClientOAuth2({
        clientId: "portal",
        accessTokenUri: tokenUri,
        authorizationUri: authorizationUri,
        redirectUri: `${window.location.protocol}//${window.location.host}/oauth2/callback`,
        scopes: ["email", "profile", "openid"],
        responseType: ["code", "id_token"],
        query: {
          resource: [this.tokenService]
        }
      })
      notify("OAuth 2.0 client configured")
      if (
        !!sessionStorage.getItem("openid.access")
      ) {
        this.token = this.oauth2.createToken(
          sessionStorage.getItem("openid.access"),
          sessionStorage.getItem("openid.refresh"),
          "code",
          {data: {id_token: sessionStorage.getItem("openid.ident")}}
        )
        await this.refreshToken()
      }

      if (!!sessionStorage.getItem("openid.ident") && this.token) {
        this.subject = jwt_decode(sessionStorage.getItem("openid.ident"))
      }
    }

    async exchange({audience, scope, forceRefresh}) {
      if (!!forceRefresh) {
        await this.refreshToken()
      }
      if (!!this.token) {
        console.log("DEBUG: Refreshing token")
        var {status, data} = await this.axios.post(
          `${this.tokenService}/token/exchange`,
          {
            grant_type: "urn:ietf:params:oauth:grant-type:token-exchange",
            audience: audience,
            scope: scope,
            requested_token_type: "urn:ietf:params:oauth:token-type:access_token",
            subject_token_type: "urn:ietf:params:oauth:token-type:jwt",
            subject_token: this.token.accessToken
          }
        )
        return data.access_token
      } else {
        console.log("WARNING: No valid access token.")
        return null
      }
    }

    getResourceFromQualname(qualname) {
      const [name] = qualname.split('.', 1)
      const method = qualname.split('.').slice(1).join('.')
      return [this.resources[name], method]
    }

    async get(qualname, opts) {
      const [resource, method] = this.getResourceFromQualname(qualname)
      return await resource.get(method, (!!opts) ? opts : {})
    }

    async handleErrorResponse(error) {
      console.log("DEBUG: Intercepting non-200 response from STS.")
      if (error.response === undefined) {
        console.log("WARNING: Network error")
        return Promise.reject(error)
      }
      const response = error.response
      const dto = error.response.data
      const request = error.config
      if (!([401, 403].includes(response.status))) {
        console.log("WARNING: Non-authentication related error, unable to recover.")
        return Promise.reject(error)
      }

      // Reject this Promise if the request was already retried.
      if (request._retry === true) {
        console.log("WARNING: Retried request failed after authentication.")
        return Promise.reject(error)
      } else {
        request._retry = true
      }

      // If the token was expired, then refresh it at the
      // identity provider.
      console.log(`DEBUG: Caught ${dto.code} from STS`)
      var result = null
      switch (dto.code) {
        case "CREDENTIAL_EXPIRED":
          await this.refreshToken()
          result = this.axios(request)
          break
        case "TRUST_ISSUES":
          // In this case the STS does not trust the IdP, which
          // is a fatal error caused by misconfiguration.
        default:
          result = Promise.reject(error)
      }

      return result
    }

    async post(qualname, opts) {
      const [resource, method] = this.getResourceFromQualname(qualname)
      return await resource.post(method, (!!opts) ? opts : {})
    }

    async refreshToken() {
      if (!!this.token) {
        try {
          this.persistToken(await this.token.refresh())
        } catch (e) {
          this.token = null
          this.subject = null
        }
      }
    }

    persistToken(token) {
      this.token = token
      sessionStorage.setItem("openid.access", token.accessToken)
      sessionStorage.setItem("openid.refresh", token.refreshToken)
      if (!!token.data.id_token) {
        sessionStorage.setItem("openid.ident", token.data.id_token)
      }
    } 

    async setup(notify) {
      const self = this
      notify("Discovering identity provider")
      try {
        var response = await axios({
          method: "get",
          url: `${this.identityProvider}/.well-known/openid-configuration`
        })
      } catch (e) {
        notify("Server misconfiguration!")
        throw e
      }

      // Use the response from /.well-known/openid-configuration to configure
      // the OAuth 2.0 client and refresh any existing tokens.
      const metadata = response.data
      if (
        (!metadata.authorization_endpoint)
        || (!metadata.token_endpoint)
      ) {
        notify("Server misconfiguration!")
        return
      }

      // This method is expected to also refresh the token, and set it to
      // null if the refresh token was expired.
      await self.configureOAuth2(
        metadata.authorization_endpoint,
        metadata.token_endpoint,
        notify
      )

      // Setup resources
      for (const name in this.resources) {
        var resource = this.resources[name]
        await resource.configure(this, notify)
      }
    }
}


export default ServiceAgent
