import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle, faQuestionCircle
} from '@fortawesome/free-solid-svg-icons'


const ICONS = {
  PENDING: [
    "var(--ion-color-dark)",
    faQuestionCircle
  ],
  ACTIVE: [
    "var(--ion-color-success)",
    faCheckCircle
  ]
}


const StatusIcon: React.FC = ({status, ...props}) => {
  const [color, icon] = ICONS[status]
  return (<FontAwesomeIcon
      icon={icon}
      style={{
        color: color,
        marginLeft: "5px",
        marginRight: "5px"
      }}
      {...props}
    />)
}


export default StatusIcon

