import { Route } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

import Application from './Application'
import ApplicationPage from './ApplicationPage'


type DashboardApplicationProps = {
  displayMode: string
}


const DashboardApplication: React.FC = ({displayMode}) => {
  const menu = {
    title: "Home",
    href: "/home",
    iconComponent: FontAwesomeIcon,
    iconProps: {
      icon: faHome
    },
    items: []
  }
  return (
    <Application displayMode={displayMode} menu={menu}>
      <Route exact path="/home">
        <ApplicationPage title="Home">
          <p>Welcome to the Molano portal!</p>
        </ApplicationPage>
      </Route>
    </Application>
  )
}


export default DashboardApplication
