

export interface IStore {
  getRevenue: () => number
  getStatus: () => string
  getTitle: () => string
  synchronize: () => boolean
}


export default class Store<IStore> {

  constructor(service, dto) {
    this.metadata = dto.metadata
    this.service = service
    this.spec = dto.spec
  }

  public getRevenue(): string {
    return this.spec.revenue
  }

  public getStatus(): string {
    return this.spec.status
  }

  public getTitle(): string {
    return this.spec.domain
  }

  public async synchronize() {
    const {metadata, spec} = await this.service.post(this.getSyncUrl())
    this.metadata = metadata
    this.spec = spec
    this.spec.status = "ACTIVE"
  }

  private getSyncUrl() {
    return this.metadata.links.sync
  }
}
