import { IStore } from './Store'


interface IAccount {
  getName: () => string
  getStores: () => IStore[]
}


class Account<IAccount> {
  private metadata
  private service
  private spec

  constructor(service, dto) {
    this.metadata = dto.metadata
    this.service = service
    this.spec = dto.spec
  }

  public getName() {
    return this.spec.name
  }

  async getStores(): IStore[] {
    return this.service.get(this.metadata.links.stores, {})
  }
}


export default Account
