import {
  IonPage,
  IonRouterOutlet
} from '@ionic/react'

import SideMenu from '../components/SideMenu'


type ApplicationProps = {
  children?: React.ReactNode
  displayMode: string
  menu: Object
}


const Application: React.FC<ApplicationProps> = ({
  children,
  displayMode,
  menu
}) => {
  var node = null
  switch (displayMode) {
    case "link":
      node = (<SideMenu {...menu}/>)
      break
    default:
      node = (
        <IonPage>
          <IonRouterOutlet animated={false}>
            {children}
          </IonRouterOutlet>
        </IonPage>
      )
  }
  return node
}


export default Application

