

interface PortalProps {
  children: React.ReactNode
  logo: string
  oauth2CallbackUrl: string
  onBoot: CallbackFunctionVariadic
  splashComponent: React.FC
  splashComponentProps: object
}


/* The Portal component is the main wrapper for any application in the
   Unimatrix One landscape, regardless of implementation. A portal may consist
   of one or many pages, a single or multiple (sub)applications.
*/
const Main: React.FC<PortalProps> = ({
  children,
  logo,
  onBoot,
  splashComponent,
  ...props
}) => {
  const SplashPage = splashComponent
  const splashComponentProps = (!!props.splashComponentProps)
    ? props.splashComponentProps
    : {}
  const oauth2CallbackUrl = (!!props.oauth2CallbackUrl)
    ? props.oauth2CallbackUrl
    : "/oauth2/callback"

  // As the OAuth 2.0 callback is expected to communicate with its parent
  // window (and be closed by it), there is no need for any boot procedures
  // or splash screens.
  const params = new URLSearchParams(window.location.search)
  if (window.location.pathname === oauth2CallbackUrl) {

    // If there is no opener, the user visited this page by accident. Redirect
    // to the root page. Be aware that any misconfiguration here can lead to
    // infinite redirect loops. As a general rule, applications should not
    // automatically redirect unauthenticated windows/tabs to the identity
    // provider and instead prompt the user; making the redirect explicitely
    // initiated by the user.
    if (!window.opener) {
      window.location.href = "/"
      return
    }

    // We expect here that the OAuth 2.0 login flow was initiated from a
    // separate window - this is needed to maintain state and for compatibility
    // with certain PWA implementations.
    window.opener.postMessage({
      code: params.get("code"),
      state: params.get("state"),
      url: window.location.href
    })
    console.log("DEBUG: Notified parent window with URL")
    return null
  }

  return (
    <SplashPage
      logo={logo}
      onBoot={onBoot}
      {...splashComponentProps}
    >
      {children}
    </SplashPage>
  )
}


export default Main
