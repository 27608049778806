import axios from 'axios';

import Unauthorized from './Unauthorized'


interface IResource {
  agent: any
  audience: string
  configure: (any, any) => void
  formatUrl: (string) => string
  metadata: any
  endpoint: string
}


class Resource<IResource> {
  private exceptionClasses: object = {
    UNAUTHORIZED: Unauthorized
  }

  constructor({endpoint, audience, scope}) {
    this.accessToken = null
    this.agent = null
    this.audience = (!!audience) ? audience : endpoint
    this.axios = axios.create()
    this.endpoint = endpoint
    this.metadata = {}
    this.scope = scope

    // Intercept request to add the access token that we
    // retrieved from the Security Token Service (STS), and
    // intercept responses to refresh the token if it expired.
    this.axios.interceptors.request.use(
      async (config) => await this.configureRequest(config),
      error => Promise.reject(error)
    )

    // Intercept responses to determine if the credentials
    // were expired. All other cases indicate a configuration
    // error so we don't handle them.
    this.axios.interceptors.response.use(
      (response) => { return response },
      async (error) => await this.handleErrorResponse(error)
    )
  }

  formatUrl(name, params) {
    try {
      new URL(name)
      var url = name
    } catch (_) {
      var url = this.metadata.catalog[name].url
        .format((params !== undefined) ? params : {})
    }
    return url
  }

  async configure(agent, notify) {
    const url = new URL(this.audience)
    notify(`Connecting API https://${url.hostname}`)
    const response = await axios.get(`${this.endpoint}/.well-known/self`)
    if (response.status != 200) {
      notify("Network error!")
      return
    }
    this.metadata = response.data
    this.agent = agent

    // Ensure that we have a valid token to begin with by
    // exchanging the credential from the agent. Do not refresh
    // the id_token since we assume that the configure method
    // is always called just after it has been received.
    this.accessToken = await agent.exchange({
      audience: this.audience,
      scope: this.scope,
      forceRefresh: false
    })
  }

  async configureRequest(config) {
    config.headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
    if (this.accessToken !== null) {
      config.headers.Authorization = `Bearer ${this.accessToken}`
    }
    return config
  }

  async exchange() {
    return await this.agent.exchange({
      audience: this.audience,
      scope: this.scope
    })
  }

  async get(endpoint, {urlParams, query}) {
    const url = this.formatUrl(endpoint, urlParams)
    var response = await this.axios.get(url)
    return this.agent.processResponse(this, response.data)
  }

  async post(endpoint, params) {
    const {json, urlParams, query} = (params !== undefined)
      ? params
      : {}
    const url = this.formatUrl(endpoint, urlParams)
    var response = await this.axios.post(url, json)
    return this.agent.processResponse(this, response.data)
  }

  async handleErrorResponse(error) {
    console.log("DEBUG: Intercepting non-200 response.")
    if (error.response === undefined) {
      console.log("WARNING: Network error")
      return Promise.reject(error)
    }
    const response = error.response
    const dto = error.response.data
    const request = error.config
    if (!([401, 403].includes(response.status))) {
      console.log("WARNING: Non-authentication related error, unable to recover.")
      return Promise.reject(error)
    }

    // Deteremine if there are any error not related to the
    // authentication.
    const ExceptionClass = this.exceptionClasses[dto.code]
    if (ExceptionClass !== undefined) {
      return Promise.reject(dto)
    }

    // Reject this Promise if the request was already retried.
    if (request._retry === true) {
      console.log("WARNING: Retried request failed after authentication.")
      return Promise.reject(error)
    }

    // Refresh the token and retry the request.
    request._retry = true
    this.accessToken = await this.exchange()
    console.log(`DEBUG: Refreshed access token for ${this.audience}`)
    console.log(`DEBUG: Retrying ${request.url}`)
    return this.axios(request)
  }
}


export default Resource
