import {Route} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCogs,
  faFileInvoice,
  faHome,
  faShippingFast,
  faShoppingCart,
  faStore,
  faWarehouse
} from '@fortawesome/free-solid-svg-icons';

import DoubleFolio from '../../components/DoubleFolio'
import Application from '../Application'
import ApplicationPage from '../ApplicationPage'
import  StoresPage from './StoresPage'


type VirtualWarehouseApplicationProps = {
  displayMode: string
}


const VirtualWarehouseApplication: React.FC = ({
  displayMode
}) => {
  const menu = {
    iconComponent: FontAwesomeIcon,
    iconProps: {
      icon: faWarehouse
    },
    href: "/api",
    title: "Virtual Warehouse API",
    items: [
      {
        title: "Stores",
        href: "",
        iconComponent: FontAwesomeIcon,
        iconProps: {
          icon: faStore
        }
      },
      {
        title: "Configure catalog",
        href: "/catalog",
        iconComponent: FontAwesomeIcon,
        iconProps: {
          icon: faCogs
        }
      },
      {
        title: "Orders",
        href: "/orders",
        iconComponent: FontAwesomeIcon,
        iconProps: {
          icon: faShoppingCart
        }
      },
      {
        title: "Shipments",
        href: "/shipments",
        iconComponent: FontAwesomeIcon,
        iconProps: {
          icon: faShippingFast
        }
      },
      {
        title: "Billing",
        href: "/billing",
        iconComponent: FontAwesomeIcon,
        iconProps: {
          icon: faFileInvoice
        }
      },
    ]
  }

  return (
    <Application displayMode={displayMode} menu={menu}>
      <Route path="/api/catalog">
        <ApplicationPage menu={menu} title="Catalog">
        <p>
          Configure your catalogs here. A catalog contains the products that
          the Virtual Warehouse API makes available to your webshop(s).
        </p>
        </ApplicationPage>
      </Route>
      <Route exact path="/api">
        <ApplicationPage gutter="0" menu={menu} title="Stores" maxWidth="768">
          <StoresPage/>
        </ApplicationPage>
      </Route>
      <Route path="/api/orders">
        <ApplicationPage menu={menu} title="Orders">
          <p>
            There are currently no orders.
          </p>
        </ApplicationPage>
      </Route>
      <Route path="/api/shipments">
        <ApplicationPage menu={menu} title="Shipments">
          <p>
            There are currently no shipments in progress.
          </p>
        </ApplicationPage>
      </Route>
      <Route path="/api/billing">
        <ApplicationPage menu={menu} title="Billing">

        </ApplicationPage>
      </Route>
    </Application>
  )
}


export default VirtualWarehouseApplication

