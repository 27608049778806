import { useEffect, useState } from 'react'
import {
  useIonActionSheet, useIonToast, IonItem, IonLabel, IonLoading,
  IonList, IonListHeader, IonText
} from '@ionic/react'
import { Loading } from '@blackwizards/ionic-react'

import { useApplication } from '../../contexts'
import StatusIcon from './StatusIcon'


const StoreListItem: React.FC = ({revenue, status, onClick, title}) => {
  return (
    <IonItem button onClick={onClick}>
      <IonLabel>
        <h2>
          {title}
          <StatusIcon status={status}/>
        </h2>
        <p>
          €{revenue}
        </p>
      </IonLabel>
    </IonItem>
  )
}


const StoresPage: React.FC = () => {
  const [{activeAccount}] = useApplication()
  const [error, setError] = useState(null)
  const [loadingMessage, setLoadingMessage] = useState(null)
  const [present, dismiss] = useIonActionSheet()
  const [presentToast, dismissToast] = useIonToast()
  const [stores, setStores] = useState(null)

  useEffect(() => {
    if (stores !== null) {
      return
    }
    activeAccount.getStores()
      .then(setStores)
      .catch(({code, ...dto}) => {
        switch (code) {
          case "UNAUTHORIZED":
            setError("You do not have permission to view stores in this account.")
            break
          default:
            setError("Uncaught exception.")
        }
      })
  }, [stores])

  const content = (error !== null)
    ? (<div style={{paddingLeft: "24px", paddingRight: "24px"}}>
        {error}
      </div>)
    : (
      <IonList>
        <IonLoading
          isOpen={loadingMessage !== null}
          message={loadingMessage}
        />
        {((stores === null) ? [] : stores.items).map((store, index) => {
          const actionSheet = {
              header: store.getTitle(),
              buttons: [
                  {text: "Remove", role: "destructive"},
                  {
                    text: "Synchronize",
                    handler: () => {
                      setLoadingMessage("Syncing...")
                      dismiss().then(() => {
                        store.synchronize()
                          .then(() => {
                            setLoadingMessage(null)
                            presentToast(`Synced ${store.getTitle()}`)
                          })
                          .catch(() => {
                            setLoadingMessage(null)
                            presentToast("Something went wrong.", 2500)
                          })
                      })
                    }
                  },
                  {text: "Configure"},
                  {text: "View orders"},
                  {text: "View shipments"},
                  {text: "Cancel", role: "cancel"}
              ]
          }
          return (<StoreListItem
              key={`store-${index}`}
              onClick={() => present(actionSheet)}
              revenue={store.getRevenue()}
              status={store.getStatus()}
              title={store.getTitle()}
          />)
        })}
      </IonList>
    )

  return (
        <>
      <IonText>
        <p style={{padding: "24px 24px 0px 24px"}}>
          Stores are external systems, such as WooCommerce or Magento, that
          implement the Virtual Warehouse API to fulfill orders.
        </p>
      </IonText>
        <Loading loading={stores === null && error === null}>
          {content}
        </Loading>
        </>
  )
}


export default StoresPage
