import { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom';
import { IonLoading, IonList, IonRouterOutlet, IonTitle } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Loading } from '@blackwizards/ionic-react'
import { MainMenu } from '@blackwizards/ionic-react'
import { SplashPage } from '@blackwizards/ionic-react'

import Main from './components/Main'
import {useApplication} from './contexts'
import logo from './components/logo-square-md.png'
import Header from './components/Header'
import DashboardApplication from './pages/DashboardApplication'
import LoginRequired from './pages/LoginPage'
import RequireAnyAccountPage from './pages/RequireAnyAccountPage'
import VirtualWarehouseApplication from './pages/VirtualWarehouseApplication'


const Router: React.FC = ({client}) => {
  const [isBooting, setIsBooting] = useState(true)
  const [state, dispatch] = useApplication()
  const {accounts, activeAccount, subject} = state
  const isAuthenticated: boolean = !!subject

  const onBoot = ({setMessage, setReady}) => {
    const onFinished = (timeout) => {
      setTimeout(() => {
        setReady()
      }, timeout)
    }
    setMessage("Initializing application")
    setTimeout(() => {
      client.setup(setMessage).then(() => {
        setMessage((!!client.subject) ? `Welcome back, ${client.subject.given_name}` : "Done")
        if (!!client.subject) {
          dispatch({subject: client.subject})
          onFinished(1500)
          return
        }
        onFinished(250)
      })
    }, 500)
  }

  useEffect(() => {
    if (!subject) {
      console.log("Subject is not authenticated.")
      return
    }

    // Get accounts and set them in the state. This allows browsing the portal
    // pages without being authenticated.
    client.get("molano.accounts.index")
      .then((accountsList) => {
        dispatch({
          accounts: accountsList.items,
          activeAccount: (accountsList.metadata.totalItems > 0)
            ? accountsList.items[0]
            : null
        })
      })
  }, [subject])

  // If the accounts are retrieved, then we finished booting.
  useEffect(() => {
    if (accounts !== null && !!isBooting) {
      console.log("DEBUG: Boot finished.")
      setIsBooting(false)
    }
  }, [accounts])

  const header = (<Header title="Molano"/>)
  const realmSelector = (!!activeAccount)
    ? (<IonTitle>{activeAccount.getName()}</IonTitle>)
    : null
  const menu = (
    <MainMenu contentId="main" title="Molano" realmSelector={realmSelector}>
      <IonList>
        <DashboardApplication displayMode="link"/>
        <VirtualWarehouseApplication displayMode="link"/>
      </IonList>
    </MainMenu>
  )

  return (
    <Main logo={logo} onBoot={onBoot} splashComponent={SplashPage}>
      <IonReactRouter animated={false}>
        <LoginRequired subject={subject} logo={logo}>
          {menu}
          {header}
          <Loading loading={!!isBooting} message="Checking account..." modal>
            <RequireAnyAccountPage>
              <IonRouterOutlet id="main" animated={false}>
                <Route path="/api" component={VirtualWarehouseApplication}/>
                <Route path="/home" component={DashboardApplication}/>
                <Route render={() => <Redirect to="/home"/>}/>
              </IonRouterOutlet>
            </RequireAnyAccountPage>
          </Loading>
        </LoginRequired>
      </IonReactRouter>
    </Main>
  )
}


export default Router
