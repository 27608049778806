import {useApplication} from '../contexts'
import Application from './Application'
import ApplicationPage from './ApplicationPage'


const RequireAnyAccountPage: React.FC = ({children}) => {
  const [state] = useApplication()
  const {accounts} = state
  if (accounts.length > 0) {
    return children
  }
  return (
    <Application>
      <ApplicationPage>
        <p>
          You don't have any company account configured yet. Contact
          sales@molano.nl to start the onboarding.
        </p>
        <p>You are signed in as {state.subject.name} (id: {state.subject.sub}).</p>
      </ApplicationPage>
    </Application>
  )
}


export default RequireAnyAccountPage
