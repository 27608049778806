import ServiceAgent from './unimatrix-client'
import { Account, Store } from './domain'


const client = new ServiceAgent({
  identityProvider: "https://molano.webidentity.id",
  tokenService: "https://sts.unimatrixapis.com",
  objectClasses: {
    "api.molano.nl/v1alpha1/Account": Account,
    "api.molano.nl/v1alpha1/Store": Store
  },
  services: [
    {
      audience: "https://api.molano.nl",
      //endpoint: "https://localhost:8000",
      endpoint: "https://api.molano.nl",
      name: "molano",
      scope: "molano.portal.accounts.*"
    },
    //{
    //  audience: "https://quote.molano.nl",
    //  //endpoint: "https://localhost:8001",
    //  endpoint: "https://quote.molano.nl",
    //  name: "iqs",
    //  scope: "molano.quotes.*"
    //},
  ]
})


export default client
