import {
  IonButton, IonContent, IonCol, IonGrid, IonItem, IonLabel, IonList, IonLoading,
  IonPage, IonRow, IonTitle
} from '@ionic/react'
import { useState } from 'react'
import { Redirect, Route } from 'react-router-dom'

import client from '../api'
import {useApplication} from '../contexts'
import './LandingPage.css'


const LoginRequired: React.FC = ({children, logo, subject}) => {
  const [state, dispatch] = useApplication()
  const [submitted, setSubmitted] = useState(false)
  const headerImage = (!!logo)
    ? (<img src={logo} alt="Logo" height="24"/>)
    : null
  if (!!subject) {
    return children
  }
  if (!!submitted) {
    return (<IonLoading isOpen={true} message="Signin in..." modal/>)
  }
  return (
    <IonPage className="landing-page">
      <IonContent fullscreen>
        <IonGrid style={{minHeight: "100%", padding: "0px"}}>
          <IonRow className="container" style={{height: "100vh"}}>
            <IonCol className="right">
              <div className="content"></div>
            </IonCol>
            <IonCol className="left">
              <div className="content">
                <IonList lines="none">
                  <IonItem>
                    {headerImage}
                    <IonLabel>
                      <h1 style={{fontSize: "16px"}}>Get started with Molano</h1>
                    </IonLabel>
                  </IonItem>
                </IonList>
                <h2>Sign in</h2>
                <p>
                  Click <strong>Continue</strong> to sign in with your personal
                  email address. If you don't have a personal account, we will
                  create one for you.
                </p>
                <p>
                  There will be an opportunity to link your company account(s)
                  after signing in.
                </p>
                <div style={{textAlign: "center", width: "100%"}}>
                  <IonButton
                    onClick={() => {
                      setSubmitted(true)
                      client.initiateLogin((subject) => {
                        dispatch({subject: subject})
                      })
                    }}
                    strong
                    style={{marginTop: "44px"}}
                  >
                    Continue
                  </IonButton>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}


export default LoginRequired
