import { menuController } from "@ionic/core";
import {
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonToolbar
} from '@ionic/react'
import { useRouteMatch, useHistory, Route } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp
} from '@fortawesome/free-solid-svg-icons';


type SideMenuProps = {
  displayMode: string
  href: string
  iconComponent: any
  iconProps: any
  title: string
}


const getIcon = (Component, initProps) => {
  const props = (!!initProps) ? initProps : {}
  props.style = {
    color: "rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.54)",
    fontSize: "16px",
    width: "64px"
  }
  return (!!Component) ? (<Component {...props}/>) : null
}


const SideMenu: React.FC<SideMenuProps> = ({
  displayMode,
  href,
  iconComponent,
  iconProps,
  items,
  title
}) => {
  const history = useHistory()
  const url = href
  var node = null
  switch (displayMode) {
    case "detail":
      node = (
        <nav id="app-navigation"
          onMouseEnter={() => {
          }}
          onMouseLeave={() => {
          }}
        >
          <IonHeader
            mode="ios"
            onClick={() => {
              const nav = document.getElementById("app-navigation");
              if (nav.classList.contains("expanded")) {
                nav.classList.remove("expanded")
              } else {
                nav.classList.add("expanded")
              }
          }}>
            <IonToolbar>
              <IonList mode="md" lines="none">
                <IonItem style={{height: "46px"}}>
                  {getIcon(iconComponent, iconProps)}
                  <IonLabel><h2>{title}</h2></IonLabel>
                  <FontAwesomeIcon className="d-portrait d-collapsed" icon={faChevronDown} />
                  <FontAwesomeIcon className="d-portrait d-expanded" icon={faChevronUp} />
                </IonItem>
              </IonList>
            </IonToolbar>
          </IonHeader>
          <IonContent mode="md" scrollY={false}>
            <IonList>
              {items.map((item, index) => {
                const href = `${url}${item.href}`
                return (
                  <IonItem
                    button
                    key={`item-${index}`}
                    lines="none"
                    onClick={() => {
                      history.push(href)
                    }}
                  >
                    {getIcon(item.iconComponent, item.iconProps)}
                    <IonLabel>
                      <h3>{item.title}</h3>
                    </IonLabel>
                  </IonItem>
                )
              })}
            </IonList>
          </IonContent>
        </nav>
      )
      break;
    default:
      node = (
        <IonItem
          button
          className="heading"
          lines="none"
          mode="md"
          menuClose="main"
          onClick={() => {
            menuController.close()
            history.push(href)
          }}
        >
          {getIcon(iconComponent, iconProps)}
          <IonLabel>{title}</IonLabel>
        </IonItem>
      )
  }
  return node
}


export default SideMenu
