import {
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonTitle,
  IonToolbar
} from '@ionic/react'


type HeaderProps = {
  children?: React.ReactNode,
  title: string | React.ReactNode
}


const Header: React.FC<HeaderProps> = ({
  children,
  title
}) => {
  return (
    <IonHeader className="platform" mode="md">
        <IonToolbar id="main">
          <IonButtons slot="start">
            <IonMenuButton menuId="main"/>
          </IonButtons>
          {(typeof title === "string") ? (
            <IonTitle>{title}</IonTitle>
          ) : title}
        </IonToolbar>
    </IonHeader>
  )
}


export default Header;
